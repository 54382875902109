.carousel {
  margin: 50px auto;
  padding: 0 70px;
}

.carousel .carousel-item {
  min-height: 330px;
  text-align: center;
  overflow: hidden;
}

.carousel .carousel-item .img-box {
  height: 160px;
  width: 100%;
  position: relative;
}

.carousel .carousel-item img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.carousel .carousel-item h4 {
  font-size: 18px;
  margin: 10px 0;
}

.carousel .carousel-item .btn {
  color: #333;
  border-radius: 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin-top: 5px;
  line-height: 16px;
}

.carousel .carousel-item .btn:hover,
.carousel .carousel-item .btn:focus {
  color: #fff;
  background: #000;
  border-color: #000;
  box-shadow: none;
}

.carousel .carousel-item .btn i {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

.carousel .thumb-wrapper {
  text-align: center;
}

.carousel .thumb-content {
  padding: 15px;
}

.carousel-control-prev,
.carousel-control-next {
  height: 100px;
  width: 40px;
  background: none;
  margin: auto 0;
  background: rgba(0, 0, 0, 0.2);
}

.carousel-control-prev i,
.carousel-control-next i {
  font-size: 30px;
  position: absolute;
  top: 50%;
  display: inline-block;
  margin: -16px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  font-weight: bold;
}

.carousel-control-prev i {
  margin-left: -3px;
}

.carousel-control-next i {
  margin-right: -3px;
}

.carousel .item-price {
  font-size: 13px;
  padding: 2px 0;
}

.carousel .item-price strike {
  color: #999;
  margin-right: 5px;
}

.carousel .item-price span {
  color: #86bd57;
  font-size: 110%;
}

.carousel .carousel-indicators {
  bottom: -50px;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 50%;
  border-color: transparent;
  border: none;
}

.carousel-indicators li {
  background: rgba(0, 0, 0, 0.2);
}

.carousel-indicators li.active {
  background: rgba(0, 0, 0, 0.6);
}

.star-rating li {
  padding: 0;
}

.star-rating i {
  font-size: 14px;
  color: #ffc000;
}

.persianFont {
  font-family: BLotus;
}

.navfont {
  font-family: homa;
  font-size: 1.1em;
}
