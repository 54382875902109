.persianFont {
  font-family: BLotus;
}

.navfont {
  font-family: homa;
  font-size: 1.1em;
}

.carousel-container {
  /* margin: 20px; */
  padding: 0;
}

.product-item {
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.product-image {
  /* width: 100%; */
  min-height: 60vh;
  max-height: 60vh;
  object-fit: cover;
  /* margin-bottom: 15px; */
}