/* 
---------------------------------------------
footer
--------------------------------------------- 
*/

footer {
  margin-top: 60px;
  padding: 60px 0px 30px 0px;
  background-color: #2a2a2a;
}

footer .logo {
  margin-bottom: 20px;
}

footer ul li {
  display: block;
  margin-bottom: 10px;
}

footer ul li a {
  font-size: 14px;
  color: #fff;
  transition: all .3s;
}

footer ul li a:hover {
  color: #aaa;
}

footer h4 {
  letter-spacing: 0.5px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}

footer .under-footer {
  margin-top: 30px;
  padding-top: 30px;
  text-align: center;
  border-top: 1px solid rgba(250,250,250,0.3);
}

footer .under-footer ul {
  margin-top: 20px;
}

footer .under-footer ul li {
  display: inline-block;
  margin: 0px 10px;
}

footer .under-footer ul li a {
  font-size: 22px;
}

footer .under-footer p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

footer .under-footer a {
  color: #cff;
}

footer .under-footer a:hover {
  color: #c66;
}

.persianFont {
  font-family: BLotus;
}

.navfont {
  font-family: homa;
  font-size: 1.4em;
}

.iranfont{
  font-family: iranyek;
}

