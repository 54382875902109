.persianFont {
  font-family: BLotus;
}

.navfont {
  font-family: iranyek;
}

@media (max-width: 576px) {
  .border-sm {
    border: 1px solid gold;
    border-radius: 1%;
    padding: 2%;
    margin-right: 0;
    margin-left: 0;
    margin-top: 20%;
  }
}